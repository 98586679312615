.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 300px; /* 부모 요소인 모달 높이 지정 */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
  height: 70%; /* 그리드 컨테이너의 높이를 % 단위로 설정 */
}

.grid-item {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 100%; /* 각 그리드 아이템의 높이를 % 단위로 설정 */
}

.red {
  background-color: #FF9999;
}

.yellow {
  background-color: #FFDB76;
}

.green {
  background-color: #c2ffb0;
}

.grid-item:hover {
  background-color: #ddd;
}
