@font-face {
  font-family: "omyu_pretty";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-01@1.0/omyu_pretty.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* 실제 배경색 */
  background-color: #000467;
}
.main > .header {
  display: flex;
  flex-basis: 7vh;
  /* background-color: #282b80; */
  margin: 8% 4% 5% 4%;
  /* border-right: solid; */
  border-bottom: solid;
  border-color: #ffc90c;
  border-width: thin;
  /* border-radius: 1rem; */
}
.main > .header > .menu-left {
  /* header 좌우의 width 값은 같아야한다 */
  flex-basis: 10vw;
  /* background-color: #FFC90C; */
  background-image: url(../../../public/menuIcon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
.main > .header > .habit-title {
  display: flex;
  align-items: center;
  text-align: center;
  /* flex-grow: 1; */
  padding: 0 5%;
  color: whitesmoke;
  font-family: "omyu_pretty";
  font-size: x-large;
  font-weight: bold;
  word-break: keep-all;
}
.main > .header > .menu-right {
  /* header 좌우의 width 값은 같아야한다 */
  flex-basis: 15vw;
}
.main > .calendar {
  flex-basis: 40vh;
  background-color: #282b80;
  margin: 2% 4% 2% 4%;
  padding: 5% 5%;
  border-radius: 1rem;
}
/* START : react-calendar CSS */
.react-calendar {
  width: auto;
  max-width: 100%;
  height: 45vh;
  padding: 5%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: "omyu_pretty";
  line-height: 1.125em;
}
/* 이전, 다음 버튼 */
.react-calendar__navigation button {
  justify-content: center;
  color: black;
  min-width: 2rem;
  background: none;
  font-family: "omyu_pretty";
  font-size: 1.4rem;
  font-weight: bolder;
  white-space: nowrap;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  /* 배경색과 동일해야함 */
  background-color: white;
}
.react-calendar__navigation button:disabled {
  /* 배경색과 동일해야함 */
  background-color: white;
}
/* 요일 */
abbr[title] {
  text-decoration: none;
}
.react-calendar__month-view__weekdays {
  font-family: "omyu_pretty";
  font-size: 1rem;
  font-weight: bolder;
}
/* 오늘 */
.react-calendar__tile--now {
  background: #b8b8ff63;
  border-radius: 6px;
  font-weight: bolder;
  color: #5a6dd8;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #6f48eb;
}
/* 현재 클릭한 날 */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background: white; */
  /* color: #6f48eb; */
  /* border-radius: 6px; */
  /* font-weight: 1000; */
}
.react-calendar__tile--active {
  /* 배경색과 동일해야함 */
  /* background: white; */
  /* border-radius: 6px; */
  /* font-weight: bolder; */
  color: #6f48eb;
  border: 2px solid #6f48eb !important ;
  box-sizing: border-box; /* 요소 크기 변경 방지 */
  margin: 0; /* 불필요한 margin이 추가되지 않도록 */
  padding: 0; /* 불필요한 padding이 추가되지 않도록 */
}
  /* 배경색과 동일해야함 */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* 배경색과 동일해야함 */
  /* background: white; */
  /* font-weight: bolder; */
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  visibility: hidden;
}
/* END : react-calendar CSS */
.main > .status {
  flex-basis: 16vh;
  background-color: #282b80;
  margin: 2% 4%;
  border-radius: 1rem;
}
.main > .button-wrapper {
  flex-basis: 15vh;
  margin: 0% 4%;
}
/* 완료날짜 stamp 표시 */
.stamp-success {
  background-color: #c2ffb0;
  border-radius: 50%;
}
/* 하단 오늘 도장 찍기 버튼  */
.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.today-success-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 8vh;
  border-radius: 10px;
  font-size: xx-large;
  font-family: "omyu_pretty";
  /* font-family: inherit; */
  background-color: #ffc90c;
  color: black;
  font-weight: bold;
  box-shadow: 5px 5px 5px 5px;
}
.today-success-button:active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 8vh;
  border-radius: 10px;
  font-size: xx-large;
  font-family: "omyu_pretty";
  /* font-family: "inherit"; */
  background-color: #ffc90c;
  color: black;
  font-weight: bold;
  box-shadow: 5px 5px 5px 5px inset;
}
/* Apex Chart */
.apexcharts-toolbar {
  visibility: hidden;
}
/* Gloabal Loading */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}