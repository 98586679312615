.intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #3498db;
}

.title {
  font-size: 2rem;
  color: #fff; /* 흰색 텍스트 */
  margin-bottom: 20px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

input {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  border: none;
}

.submit-button {
  background-color: #2ecc71; /* 버튼의 배경색 */
  color: #fff; /* 버튼 텍스트의 색상 */
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: #27ae60; /* 버튼의 호버 상태 배경색 */
}

.height-2rem {
  height: 2rem;
}
